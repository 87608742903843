<template>
    <div>
        <v-dialog
            v-model="testAddDialog"
            max-width="500px"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            persistent>
            <test-add-form
                :key="formKey"
                @input="onAdd"
                @cancel="onCancel"
                @open="testAddDialog = true" />
        </v-dialog>
        <v-form>
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col cols="9">
                            <span class="headline">Tests for Sample {{ sample.code }}</span>
                        </v-col>
                        <v-col cols="3">
                            <v-btn
                                color="tertiary"
                                dark
                                class="mr-2 mb-2 float-right"
                                @click="testAddDialog = true">
                                Add Test
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-text>
                    <v-data-table
                        class="elevation-1"
                        :headers="headers"
                        :items="items"
                        :loading="isLoading"
                        item-key="id"
                        disable-filtering
                        disable-pagination
                        hide-default-footer>
                        <template v-slot:item.action="{ item }">
                            <div class="d-flex flex-nowrap justify-end">
                                <v-btn
                                    color="tertiary"
                                    dark
                                    class="mr-2 float-right"
                                    :to="getResultsRoute(item)">
                                    Manage Results
                                </v-btn>
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="quaternary"
                        text
                        @click="$emit('close')">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </div>
</template>

<script>
import axios from "axios"
import { equal, or, and } from "@/services/filtering"
export default {
    components: {
        testAddForm: () => import("@/forms/TestAddForm")
    },
    props: {
        sample: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
            headers: [
                { text: "Name", value: "name" },
                { text: "Test Group", value: "testGroup" },
                { text: '', value: 'action' }
            ],
            items: [],
            testAddDialog: false,
            formKey: 0
        };
    },
    async mounted() {
        await this.load();
    },
    methods: {
        async load() {
            let sampleTests = await this.getSampleTests();
            let tests = await this.getTests(sampleTests);
            let testGroups = await this.getTestGroups(tests);
            this.assignTestGroups(tests, testGroups, sampleTests);
        },
        async getSampleTests() {
            let sampleSetTests = await this.getSampleSetTests();
            let labTests = await this.getLabTests(sampleSetTests);
            let params = {};
            let sampleFilter = equal("SampleId", this.sample.id, "Guid");
            let labTestFilter = or(labTests.map(e => equal("TestId", e.testId, "Guid")));
            params.filter = and([sampleFilter, labTestFilter]);
            let response = await axios.post("/api/SampleTests/List", params);
            return response.data.items;
        },
        async getSampleSetTests() {
            if (this.isEmpty(this.sample.sampleSetId)) {
                return [];
            }
            let params = {};
            params.filter = equal("SampleSetId", this.sample.sampleSetId, "Guid");
            let response = await axios.post("/api/SampleSetTests/List", params);
            return response.data.items;
        },
        async getLabTests(sampleSetTests) {
            if (this.isEmpty(sampleSetTests) || sampleSetTests.length === 0) {
                return [];
            }
            let params = {};
            let filter = or(sampleSetTests.map(e => equal("Id", e.labTestId, "Guid")));
            params.filter = filter;
            let response = await axios.post("/api/LabTests/List", params);
            return response.data.items;
        },
        async getTests(sampleTests) {
            let testIds = sampleTests.map(e => e.testId);
            let response = await axios.post("/api/Tests/ListFromIds", testIds);
            return response.data.items;
        },
        async getTestGroups(tests) {
            let testGroupIds = tests.map(e => e.testGroupId);
            let response = await axios.post("/api/TestGroups/ListFromIds", testGroupIds);
            return response.data.items;
        },
        assignTestGroups(tests, testGroups, sampleTests) {
            for (let i = 0; i < tests.length; i++) {
                let test = tests[i];
                let testGroup = testGroups.filter(e => test.testGroupId === e.id)[0];
                let sampleTest = sampleTests.filter(e => test.id === e.testId)[0];
                test.testGroup = testGroup.name;
                test.sampleTestId = sampleTest.id;
            }
            this.items = tests;
        },
        getResultsRoute(test) {
            let filter = {
                sampleTestId: test.sampleTestId,
                memberId: this.sample.memberId
            }
            let options = {
                name: 'testResults',
                query: { filter }
            };
            return options;
        },
        onCancel() {
            this.testAddDialog = false;
            this.formKey++;
        },
        async onAdd(value) {
            this.testAddDialog = false;
            this.formKey++;
            let sampleTest = {
                sampleId: this.sample.id,
                testId: value.testId,
                labId: value.labId
            };
            await axios.post('/api/SampleTests', sampleTest);
            await this.load();
        },
    }
};
</script>
<style scoped>
    .hover-cell {
        cursor: pointer;
    }
</style>
